import { useMsal } from '@azure/msal-react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { setSelectedTags } from 'pages/things-to-do/slices/search.slice';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import utils from 'shared/services/utilities.service';
import requestStoreService from 'shared/store/request.reducer';
import { getTags, Tag } from 'shared/store/request.thunk';
import { AppDispatch, RootState } from 'shared/store/root.store';
import SearchTag from '../search-tag/SearchTag';
import './SearchTagSlider.scss';

const SearchTagSlider: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { instance, accounts } = useMsal();
  const { language } = useSelector((state: RootState) => state.userSettings);
  const { value: allTags } = useSelector(requestStoreService.selector('searchTags'));
  const { value: searchResults } = useSelector(requestStoreService.selector('searchResults'));
  const { selectedTagIds, location } = useSelector(requestStoreService.selector('searchForm'));

  const sliderList = useRef<HTMLDivElement>(null);

  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderScrollWidth, setSliderScrollWidth] = useState(0);
  const [sliderScrollPosition, setSliderScrollPosition] = useState(0);

  // Choose a product image and set as tag image
  const allTagsWithImage = useMemo(() => {
    if (!allTags || !searchResults) return [];

    return allTags.map((tag) => {
      const productsHaveTag = searchResults.filter((product) => product.attributeidlistList.includes(tag.attributeid));
      return {
        ...tag,
        imageurl: productsHaveTag[Math.floor(Math.random() * productsHaveTag.length)]?.mainimageurl || tag.imageurl,
        productCount: productsHaveTag.length
      };
    });
  }, [allTags, searchResults]);

  // Filter out the tags that search results don't have
  const allTagsByLocation = useMemo(() => {
    if (!searchResults) return [];

    const allSearchResultTagIds = new Set(searchResults.map((product) => product.attributeidlistList).flat());

    return allTagsWithImage.filter((tag) => allSearchResultTagIds.has(tag.attributeid));
  }, [allTagsWithImage, searchResults]) as Tag[];

  // Sort by selected tags first, then sort by sortorder
  const sortedTags = useMemo(
    () =>
      allTagsByLocation.sort(
        (a, b) =>
          Number(selectedTagIds.has(b.attributeid)) - Number(selectedTagIds.has(a.attributeid)) ||
          (b.productCount as number) - (a.productCount as number)
      ),
    [allTagsByLocation, selectedTagIds]
  );

  useEffect(() => {
    location?.id &&
      dispatch(getTags({ locationid: location.id, account: accounts[0], instance, languagecode: language }));
  }, [dispatch, location?.id, accounts, instance, language]);

  const scrollLeft = () => {
    if (!sliderList.current) return;

    sliderList.current.scrollLeft -= sliderList.current.offsetWidth;
  };

  const scrollRight = () => {
    if (!sliderList.current) return;

    sliderList.current.scrollLeft += sliderList.current.offsetWidth;
  };

  const toggleTag = (tag: Tag) => {
    const newSet = new Set(selectedTagIds);
    if (!newSet.delete(tag.attributeid)) {
      newSet.add(tag.attributeid);
    }
    dispatch(setSelectedTags(newSet));
  };

  useEffect(() => {
    if (!sliderList.current) return;

    const scrollHandler = utils.debounce((event: any) => {
      setSliderScrollPosition(event.target?.scrollLeft);
    }, 250);
    sliderList.current?.addEventListener('scroll', scrollHandler, { passive: true });
    const resizeObserver = new ResizeObserver(() => {
      if (!sliderList.current) return;

      setSliderWidth(sliderList.current.offsetWidth);
      setSliderScrollWidth(sliderList.current.scrollWidth);
      // sliderList.current.scrollLeft = sliderScrollPosition;
    });
    resizeObserver.observe(sliderList.current);

    return () => {
      sliderList.current?.removeEventListener('scroll', scrollHandler);
      resizeObserver.disconnect();
    };
  }, [sliderList.current]);

  const showPrevButton = sliderScrollPosition > 0;
  const showNextButton = sliderScrollPosition < sliderScrollWidth - sliderWidth;

  return true ? (
    <div className="tag-slider">
      <div className={'prev nav' + (showPrevButton ? ' active' : '')} onClick={scrollLeft}>
        <div className="nav-icon">
          <NavigateBeforeIcon />
        </div>
      </div>
      <div className="tag-slider-list" ref={sliderList}>
        {sortedTags?.map((tag) => (
          <SearchTag
            key={tag.attributeid}
            tag={tag}
            active={selectedTagIds.has(tag.attributeid)}
            onClick={() => toggleTag(tag)}
          />
        ))}
      </div>
      <div className={'next nav' + (showNextButton ? ' active' : '')} onClick={scrollRight}>
        <div className="nav-icon">
          <NavigateNextIcon />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SearchTagSlider;
