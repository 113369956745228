import { ProductDetail } from '@BookingPlatform/grpc/Attraction/Common/ProductDetail_pb';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Rating } from '@mui/material';
import { setSelectedProduct } from 'pages/things-to-do/slices/search.slice';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import utils from 'shared/services/utilities.service';
import { AppDispatch } from 'shared/store/root.store';
import './ResultsItem.scss';

interface IResultItem {
  result: ProductDetail.AsObject;
}

const getDuration = (minutes: number) => {
  let hours = Math.floor(minutes / 60);
  let leftoverMinutes = minutes - 60 * hours;
  let hoursPlural = hours > 1 ? 's' : '';
  let minutesPlural = leftoverMinutes > 1 ? 's' : '';

  return (
    (hours > 0 ? hours + ' hour' + hoursPlural + ' ' : '') +
    (leftoverMinutes > 0 ? leftoverMinutes + ' minute' + minutesPlural : '')
  );
};

const ResultsItem: FC<IResultItem> = ({ result }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Link
      className="results-item p-4 pb-3"
      tabIndex={0}
      to={'/product-details/' + result.productid}
      onClick={() => dispatch(setSelectedProduct(result.productid))}>
      <div className="image">
        <img
          src={result.mainimageurl}
          alt=""
          onError={(event) => {
            event.currentTarget.style.display = 'none';
          }}
        />
        <div className="hover">
          <ArrowForwardIcon />
        </div>
        {result.attributeidlistList.includes(20757) && <div className="feature-tag">{t('productDetails.thingsToDo.likelyToSell')}</div>}
      </div>
      <div className="content-wrap">
        <div className="content">
          <h3 className="h4 mb-1">{result.productname}</h3>
          {result.reviewrating?.value && (
            <div className="rating mb-3">
              <Rating name="read-only" value={Number(result.reviewrating?.value)} precision={0.5} readOnly />
              {'(' + result.totalreviews + ')'}
            </div>
          )}

          <div className="description mb-3">{result.shortdescription}</div>
          {result.durationfromminutes?.value && (
            <div className="duration mb-2" id="duration" data-duration={result.durationfromminutes.value}>
              <AccessTimeIcon className="mr-1" />
              {getDuration(result.durationfromminutes.value)}
            </div>
          )}

          {/* {result.freecancellation && (
            <div className="cancellation free">
              <DoneIcon className="mr-1" />
              {t('productDetails.thingsToDo.cancellation')}
            </div>
          )} */}
        </div>

        {result.fromprice?.value && (
          <div className="price" data-price={result.fromprice.value}>
            <div className="from">{t('generic.fromPrice')}</div>
            <strong className="amount">
              {utils.formatCurrency(result.fromprice.value as any as number, result.currencycode)}
            </strong>
          </div>
        )}
      </div>
    </Link>
  );
};

export default ResultsItem;
