import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import App from 'App';
import { msalConfig } from 'authConfig';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'shared/contexts/config.context';
import HighSecurityProvider from 'shared/contexts/HighSecurityContext';
import RumProvider from 'shared/contexts/rum.context';
import { StyleProvider } from 'shared/contexts/theme.context';
import i18nConfig from 'shared/services/language.service';
import { store } from 'shared/store/root.store';
import './style/style.scss';

import(`./style/themes/${process.env.REACT_APP_SITE}.scss`);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

export const instance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HighSecurityProvider>
        <ConfigProvider>
          <I18nextProvider i18n={i18nConfig}>
            <RumProvider>
              <MsalProvider instance={instance}>
                <StyleProvider>
                  <App />
                </StyleProvider>
              </MsalProvider>
            </RumProvider>
          </I18nextProvider>
        </ConfigProvider>
      </HighSecurityProvider>
    </Provider>
  </React.StrictMode>
);
