import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Slider from '@mui/material/Slider';
import { deepmerge } from '@mui/utils';
import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import utils from 'shared/services/utilities.service';
import CheckboxGroup from './CheckboxGroup';
import { CheckboxInputs, CheckboxItem, DurationNames, SpecialsLabels, SpecialsProps, TimeNames } from './interfaces';
import './ResultsFilters.scss';

export interface FilterPriceInfo {
  minPrice: number;
  maxPrice: number;
}

interface IResultsFilters {
  onFilterChange: (data: any) => void;
  priceInfo: FilterPriceInfo;
  currencyCode: string;
  disabledSpecials: Set<SpecialsProps>;
}

interface IFilterForm {
  price: number[];
  duration: CheckboxItem[];
  time: CheckboxItem[];
  rating: number;
  specials: CheckboxItem[];
}

const SpecialsKeys = Object.keys(SpecialsProps) as (keyof typeof SpecialsProps)[];

const ResultsFilters: FC<IResultsFilters> = ({ onFilterChange, priceInfo, disabledSpecials, currencyCode }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const isSpecialDisabled = useCallback(
    (prop: SpecialsProps) => disabledSpecials.has(prop),
    [disabledSpecials]
  );

  const { setValue, getValues, watch } = useForm<IFilterForm>({
    defaultValues: {
      price: [priceInfo.minPrice, priceInfo.maxPrice],
      duration: [
        { label: 'Less than 1 hour', name: DurationNames.UnderOneHour, value: false },
        { label: '1 to 4 hours', name: DurationNames.UpToFourHours, value: false },
        { label: '4 hours to 1 day ', name: DurationNames.UpToOneDay, value: false },
        { label: 'More than 1 day', name: DurationNames.OverOneDay, value: false }
      ],
      time: [
        { label: '6am - 12pm', name: TimeNames.SixToTwelve, value: false },
        { label: '12pm - 5pm', name: TimeNames.TwelveToFive, value: false },
        { label: '5pm - 12am', name: TimeNames.FiveToTwelve, value: false }
      ],
      rating: 1,
      specials: SpecialsKeys.map((key) => ({
        name: SpecialsProps[key],
        label: SpecialsLabels[SpecialsProps[key]],
        value: false,
        disabled: isSpecialDisabled(SpecialsProps[key]),
      })),
    }
  });

  watch(['specials', 'price']);

  const starRatingLanguageTags: Record<number, string> = {
    1: 'thingToDoFilter.starsOne',
    2: 'thingToDoFilter.starsTwo',
    3: 'thingToDoFilter.starsThree',
    4: 'thingToDoFilter.starsFour',
    5: 'thingToDoFilter.starsFive'
  };

  useEffect(() => {
    setValue(
      'specials',
      getValues().specials.map((item) => ({
        ...item,
        disabled: isSpecialDisabled(item.name as SpecialsProps) && !item.value
      }))
    );
  }, [disabledSpecials, getValues, isSpecialDisabled, setValue]);

  useEffect(() => {
    setValue('price', [priceInfo.minPrice, priceInfo.maxPrice]);
  }, [priceInfo, setValue]);

  const radioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const rating = Number(event.target.value);
    onFilterChange({ ...getValues(), rating });
    setValue('rating', rating);
  };

  const checkboxChange = (event: ChangeEvent<HTMLInputElement>, index: number, type: CheckboxInputs) => {
    const changedCheckboxes = getValues()[type].map((checkbox, i) => ({
      ...checkbox,
      value: i === index ? event.target.checked : checkbox.value
    }));
    onFilterChange({ ...getValues(), [type]: changedCheckboxes });
    setValue(type, changedCheckboxes);
  };

  const priceChange = useCallback((_event: Event, value: number[] | number) => {
    const price = Array.isArray(value) ? value : [value];
    onFilterChange({ ...getValues(), price });
    setValue('price', price);
  },
    [setValue, getValues, onFilterChange]
  );

  useEffect(() => {
    const filterParams = searchParams.get('filters');

    if (filterParams) {
      const filterForm = deepmerge(getValues(), JSON.parse(filterParams));

      setValue('time', filterForm.time);
      setValue('duration', filterForm.duration);
      setValue('price', filterForm.price);
      setValue('rating', filterForm.rating);
      setValue('specials', filterForm.specials);

      onFilterChange(filterForm);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StarRadioLabel: FC<{ value: number }> = ({ value }) => (
    <FormControlLabel
      value={value}
      control={<Radio checkedIcon={<StarIcon color="secondary" />} icon={<StarBorderIcon />} />}
      label={t(starRatingLanguageTags[value])}
    />
  );

  return (
    <div className="results-filters">
      <div className="filter-group mt-3 mb-5 pb-5">
        <div className="filter-title">Price</div>

        <Slider
          getAriaLabel={() => 'Price range'}
          step={Math.ceil(priceInfo.maxPrice / 20)}
          marks
          value={getValues().price}
          min={priceInfo.minPrice}
          max={priceInfo.maxPrice}
          onChange={priceChange}
          valueLabelDisplay="auto"
        />
        <div className="price-wrap">
          <div className="min-price" data-price={getValues().price[0]}>
            {utils.formatCurrency(getValues().price[0], currencyCode)}
          </div>
          <div className="max-price" data-price={getValues().price[1]}>
            {utils.formatCurrency(getValues().price[1], currencyCode)}
          </div>
        </div>
      </div>

      <div className="filter-group mb-5 pb-5">
        <div className="filter-title mb-2">Duration</div>
        <CheckboxGroup list={getValues().duration} onChange={checkboxChange} type="duration" />
      </div>

      <div className="filter-group mb-5 pb-5">
        <div className="filter-title mb-2">Time of day</div>
        <CheckboxGroup list={getValues().time} onChange={checkboxChange} type="time" />
      </div>

      <div className="filter-group mb-5 pb-5">
        <div className="filter-title mb-2">Rating</div>
        <FormControl>
          <RadioGroup defaultValue={1} name="star-radio" onChange={radioChange}>
            <StarRadioLabel value={5} />
            <StarRadioLabel value={4} />
            <StarRadioLabel value={3} />
            <StarRadioLabel value={2} />
            <StarRadioLabel value={1} />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="filter-group">
        <div className="filter-title mb-2">Specials</div>
        <CheckboxGroup list={getValues().specials} onChange={checkboxChange} type="specials" />
      </div>
    </div>
  );
};

export default ResultsFilters;
