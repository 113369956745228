import { Currency } from 'pages/account/my-account-from/MyAccountForm';

export interface IConfig {
  defaultCurrency: Currency;
  defaultLanguage: string;
  defaultTimezone: string;
  tenantId: '68d7f8b5-59ba-4de7-8d25-68ea7829dea7';
  brandId: 1;
  salesChannelId: 2;
  languageList: IconedListItem<string>[];
  currencyList: IconedListItem<Currency>[];
}

export interface AsyncThunkOptions {
  extra: { config: Promise<IConfig> };
}

export interface IconedListItem<T> {
  value: T;
  label: string;
  iconString?: string;
  iconUrl?: string;
}

export enum PageRoute {
  ThingsToDo = '/things-to-do',
  SearchResults = '/things-to-do/search-results',
  ProductDetails = '/product-details',
  Checkout = '/checkout',
  BookingDetails = '/booking-details',
  Account = '/account',
  PaymentResult = '/payment/result',
  BookingCancellation = '/booking-cancellation',
  BookingCancellationConfirmation = '/booking-cancellation-confirmation'
}
