import { FormControl } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { FC } from 'react';
import { CheckboxInputs, CheckboxItem } from './interfaces';

interface ICheckboxGroup {
  list: Array<CheckboxItem>;
  type: CheckboxInputs;
  onChange: (data: any, index: number, type: CheckboxInputs) => void;
}

const CheckboxGroup: FC<ICheckboxGroup> = ({ list, type, onChange }) => (
  <FormControl>
    <FormGroup>
      {list.map((item, index) => (
        <FormControlLabel
          key={index}
          label={item.label}
          disabled={item?.disabled}
          control={
            <Checkbox
              name={item.name}
              checked={item.value}
              onChange={(event) => {
                onChange(event, index, type);
              }}
            />
          }
        />
      ))}
    </FormGroup>
  </FormControl>
);

export default CheckboxGroup;
