import { useMsal } from '@azure/msal-react';
import { DateTime } from 'luxon';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import requestStoreService, { RequestStatus } from 'shared/store/request.reducer';
import { productSearch } from 'shared/store/request.thunk';
import { AppDispatch } from 'shared/store/root.store';
import { SearchQuery } from '../search/Search';
import { setSearch } from '../slices/search.slice';
import Loader from './Loader';
import Results from './Results';

const SearchResultsPage: FC = () => {
  const searchResults = useSelector(requestStoreService.selector('searchResults'));
  const { currency, language } = useSelector(requestStoreService.selector('userSettings'));
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    const searchParam = searchParams.get('search');

    if (searchParam) {
      const searchQuery = JSON.parse(searchParam) as SearchQuery;
      const startDate = DateTime.fromFormat(searchQuery.startDate, 'yyyyMMdd').toJSDate();
      const endDate = DateTime.fromFormat(searchQuery.endDate, 'yyyyMMdd').toJSDate();

      dispatch(
        productSearch({
          searchForm: { date: { startDate, endDate }, location: searchQuery.location },
          instance,
          account: accounts[0]
        })
      );
      dispatch(
        setSearch({
          location: searchQuery.location,
          date: { startDate: startDate.toString(), endDate: endDate.toString() }
        })
      );
    }
  }, [currency, language]);

  return searchResults.status === RequestStatus.Loading ? <Loader /> : <Results />;
};

export default SearchResultsPage;
