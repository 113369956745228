import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { HighSecurityContext } from 'shared/contexts/HighSecurityContext';
import { getMockHighSecurity, productSearch } from 'shared/store/request.thunk';
import { AppDispatch } from 'shared/store/root.store';

const useLoginLogout = () => {
  const { instance } = useMsal();

  const dispatch = useDispatch<AppDispatch>();

  const { setIsTokenTooOld } = useContext(HighSecurityContext);

  return {
    login: async (isQuickLoginForHighSecurity: boolean) => {
      if (isQuickLoginForHighSecurity) {
        await instance.loginPopup({ ...loginRequest, prompt: 'login' });

        // all high security calls should be cleared here, if there are more think about a better pattern
        dispatch(getMockHighSecurity({ isClear: true }));
        dispatch(productSearch({ isClear: true }));
        setIsTokenTooOld(false);
      } else {
        instance.loginRedirect(loginRequest);
      }
    },
    logout: () => instance.logoutRedirect()
  };
};

export default useLoginLogout;
