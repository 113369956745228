import { BookingStatus } from '@BookingPlatform/grpc/Shared/BookingStatus_pb';
import { FC } from 'react';
import './BookingComponentStatusChip.scss';

const BookingComponentStatusChip: FC<{ status: BookingStatus }> = ({ status }) => {
  const className = status === BookingStatus.CANCELLED ? 'booking-status-cancelled' : 'booking-status-confirmed';

  const mapStatus = () => {
    switch (status) {
      case BookingStatus.CONFIRMED:
        return 'CONFIRMED';
      case BookingStatus.FAILED:
        return 'FAILED';
      case BookingStatus.INPROGRESS:
        return 'IN PROGRESS';
      case BookingStatus.PENDING:
        return 'PENDING';
      case BookingStatus.REJECTED:
        return 'REJECTED';
      case BookingStatus.CANCELLED:
        return 'CANCELLED';
    }
  };

  return <div className={className}>{mapStatus()}</div>;
};

export default BookingComponentStatusChip;
