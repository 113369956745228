export enum FilterFormKey {
  Price = 'price',
  Duration = 'duration',
  Time = 'time',
  Rating = 'rating',
  Specials = 'specials'
}

export type CheckboxInputs = 'duration' | 'time' | 'specials';

export enum DurationNames {
  UnderOneHour = 'under-one-hour',
  UpToFourHours = 'up-to-four-hours',
  UpToOneDay = 'up-to-one-day',
  OverOneDay = 'over-one-day'
}

export enum TimeNames {
  SixToTwelve = 'six-to-twelve',
  TwelveToFive = 'twelve-to-five',
  FiveToTwelve = 'five-to-twelve'
}

export enum RatingNames {
  One = 'one',
  Two = 'two',
  Three = 'three',
  Four = 'four',
  Five = 'five'
}

export enum SpecialsProps {
  ExcellentQuality = '21972',
  PopularWithTourists = '12154',
  LikelyToSellOut = '22083',
  OnceInALifetime = '11940',
  ViatorExclusiveTours = '11954',
  UniqueExperiences = '21074',
  BestValue = '6226',
  ViatorPlus = '21971',
}

export const SpecialsLabels: {[id in SpecialsProps]: string} = {
  [SpecialsProps.ExcellentQuality]: 'Excellent Quality',
  [SpecialsProps.PopularWithTourists]: 'Popular with tourists',
  [SpecialsProps.LikelyToSellOut]: 'Likely to sell out',
  [SpecialsProps.OnceInALifetime]: 'Once in a Lifetime',
  [SpecialsProps.ViatorExclusiveTours]: 'Viator Exclusive Tours',
  [SpecialsProps.UniqueExperiences]: 'Unique experiences',
  [SpecialsProps.BestValue]: 'Best Value',
  [SpecialsProps.ViatorPlus]: 'Viator Plus',
}

export interface CheckboxItem {
  label: string;
  name: string;
  value: boolean;
  disabled?: boolean;
}

export interface FilterForm {
  price: Array<number>;
  duration: Array<CheckboxItem>;
  time: Array<CheckboxItem>;
  rating: number;
  specials: Array<CheckboxItem>;
}
