import { AccountInfo, Configuration } from '@azure/msal-browser';

export const loginRequest = {
  scopes: ['api://c59667c4-f433-4481-a145-5f93438af4e6/secure_access2']
};

export const silentTokenRequest = (account: AccountInfo) => ({
  ...loginRequest,
  account
});

export const authority = 'https://login.microsoftonline.com/68d7f8b5-59ba-4de7-8d25-68ea7829dea7';

export const msalConfig: Configuration = {
  auth: {
    clientId: 'f7115fe5-2e11-436c-9cb5-17dc5b14b002',
    authority,
    redirectUri: '/'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
};
