import logo from 'assets/images/logo.png';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

const Footer: FC = () => {
  return (
    <div className="main-footer mt-auto pt-5">
      <div className="container pt-5 pb-3">
        <Link to={'/'} className="logo-link">
          <img src={logo} className="logo" alt="logo" />
        </Link>

        <span className="copy ml-auto">© {new Date().getFullYear()} Booking Platform, Inc. All rights reserved.</span>

        <span className="sep mx-3">|</span>

        <Link to={'/terms-conditions'} className="terms">
          Terms & Conditions
        </Link>

        <span className="sep mx-3">|</span>

        <Link to={'/privacy'} className="terms">
          Privacy and Cookies Statement
        </Link>
      </div>
    </div>
  );
};

export default Footer;
