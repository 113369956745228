import { Theme, ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import themeService from 'shared/services/theme.service';

export const StyleProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState<Theme | undefined>();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!theme && themeService.themeAvailable()) {
        setTheme(themeService.getTheme());
        clearInterval(interval);
      }
    }, 0);

    return () => clearInterval(interval);
  }, [theme]);

  return theme ? <ThemeProvider theme={theme}>{children}</ThemeProvider> : <>{children}</>;
};
