import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getI18n } from 'react-i18next';
import { setCurrency, setLanguage, setTimezone } from 'shared/slices/user-settings.slice';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: setCurrency,
  effect: async (action) => {
    localStorage.setItem('currencyCode', action.payload);
  }
});

listenerMiddleware.startListening({
  actionCreator: setLanguage,
  effect: async (action) => {
    localStorage.setItem('languageCode', action.payload);
    getI18n().changeLanguage(action.payload);
  }
});

listenerMiddleware.startListening({
  actionCreator: setTimezone,
  effect: async (action) => {
    localStorage.setItem('timeZone', action.payload);
  }
});

export default listenerMiddleware;
