import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { CssBaseline } from '@mui/material';
import LoadingPage from 'pages/loading-page/LoadingPage';
import Login from 'pages/login/Login';
import PaymentStatus from 'pages/payment-status/PaymentStatus';
import SearchResultsPage from 'pages/things-to-do/results/SearchResultsPage';
import { lazy, Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ErrorBoundary from 'shared/components/error-boundary/ErrorBoundary';
import Footer from 'shared/components/footer/Footer';
import Header from 'shared/components/header/Header';
import HighSecurityTemplate from 'shared/components/HighSecurityTemplate';
import PageMetrics from 'shared/components/metrics/PageMetrics';
import { PageRoute } from 'shared/interfaces/config.interface';

const NotFound = lazy(() => import('pages/not-found/NotFound'));
const Dashboard = lazy(() => import('pages/dashboard/Dashboard'));
const ThingsToDo = lazy(() => import('pages/things-to-do/ThingsToDo'));
const ProductDetails = lazy(() => import('pages/product-details/ProductDetails'));
const Checkout = lazy(() => import('pages/checkout/Checkout'));
const BookingDetails = lazy(() => import('pages/booking-details/BookingDetails'));
const Account = lazy(() => import('pages/account/Account'));
const BookingConfirmation = lazy(() => import('pages/payment-status/BookingConfirmation'));
const BookingCancellation = lazy(() => import('pages/booking-cancellation/BookingCancellation'));
const BookingCancellationConfirmation = lazy(
  () => import('pages/booking-cancellation-confirmation/BookingCancellationConfirmation')
);

function App() {
  const { inProgress } = useMsal();

  return (
    <>
      <ToastContainer position="bottom-center" />
      {inProgress === 'none' && (
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      )}
      <CssBaseline enableColorScheme />
      <AuthenticatedTemplate>
        <Router>
          <Header />
          <PageMetrics />
          <Suspense fallback={<LoadingPage />}>
            <ErrorBoundary>
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Dashboard />} />
                <Route
                  path={PageRoute.ThingsToDo}
                  element={
                    <HighSecurityTemplate>
                      <ThingsToDo />
                    </HighSecurityTemplate>
                  }
                />
                <Route
                  path={PageRoute.SearchResults}
                  element={
                    <HighSecurityTemplate>
                      <SearchResultsPage />
                    </HighSecurityTemplate>
                  }
                />
                <Route path={`${PageRoute.ProductDetails}/:productId`} element={<ProductDetails />} />
                <Route
                  path={PageRoute.Checkout}
                  element={
                    <HighSecurityTemplate>
                      <Checkout />
                    </HighSecurityTemplate>
                  }
                />
                <Route
                  path={`${PageRoute.BookingDetails}/:bookingReference`}
                  element={
                    <HighSecurityTemplate>
                      <BookingDetails />
                    </HighSecurityTemplate>
                  }
                />
                {!!process.env.REACT_APP_FEAT_USERSETTINGS && <Route path={PageRoute.Account} element={<Account />} />}
                <Route path={PageRoute.PaymentResult} element={<PaymentStatus />} />
                <Route
                  path={`${PageRoute.BookingCancellation}/:bookingReference`}
                  element={
                    <HighSecurityTemplate>
                      <BookingCancellation />
                    </HighSecurityTemplate>
                  }
                />
                <Route
                  path={`${PageRoute.BookingCancellationConfirmation}/:bookingReference`}
                  element={
                    <HighSecurityTemplate>
                      <BookingCancellationConfirmation />
                    </HighSecurityTemplate>
                  }
                />
              </Routes>
            </ErrorBoundary>
          </Suspense>
          <Footer />
        </Router>
      </AuthenticatedTemplate>
    </>
  );
}

export default App;
