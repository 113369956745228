import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tag } from 'shared/store/request.thunk';
import { SearchFieldValue } from '../search/search-field/SearchField';

export interface SearchState {
  date: { startDate?: string; endDate?: string };
  location?: SearchFieldValue;
  selectedProductId?: number;
  selectedTagIds: Set<number>;
}

export const initialState: SearchState = {
  date: { startDate: undefined, endDate: undefined },
  location: undefined,
  selectedProductId: undefined,
  selectedTagIds: new Set(),
};

export const searchSlice = createSlice({
  name: 'search-form',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<Partial<SearchState>>) => {
      state.date = {
        startDate: action.payload.date && action.payload.date.startDate,
        endDate: action.payload.date && action.payload.date.endDate
      };
      state.location = action.payload.location;
    },
    setSelectedProduct: (state, action: PayloadAction<number>) => {
      state.selectedProductId = action.payload;
    },
    setSelectedTags: (state, action: PayloadAction<Set<number>>) => {
      state.selectedTagIds = action.payload;
    },
  }
});
export const { setSearch, setSelectedProduct, setSelectedTags } = searchSlice.actions;
export default searchSlice.reducer;
