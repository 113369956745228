import { ProductDetail } from '@BookingPlatform/grpc/Attraction/Common/ProductDetail_pb';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ErrorBox from 'shared/components/error-box/ErrorBox';
import useInfiniteLoading from 'shared/hooks/useInfiniteLoading';
import utils from 'shared/services/utilities.service';
import requestStoreService from 'shared/store/request.reducer';
import TagSlider from '../search/search-tag-slider/SearchTagSlider';
import './Results.scss';
import ResultsFilters from './results-filters/ResultsFilters';
import { FilterForm, SpecialsProps } from './results-filters/interfaces';
import ResultsItem from './results-item/ResultsItem';
import filterService from './services/filter.service';

const setInitialResults = (resultList: ProductDetail.AsObject[] | null | undefined) => {
  return resultList ? structuredClone(resultList.filter((item) => !!item.productname)) : [];
};

const sortByField = (array: Array<any>, sortBy: string) => {
  const field = sortBy.split('-').pop() as string;
  const isDesc = sortBy.includes('-');

  return [...array].sort(
    (a: any, b: any) => (isDesc ? 1 : -1) * (Number(b[field]?.value || 0) - Number(a[field]?.value || 0))
  );
};

const ordering = (value: string, list: ProductDetail.AsObject[] | []) => {
  const sortedList = sortByField(list, '-reviewrating');
  switch (value) {
    case '1':
      return sortByField(sortedList, 'fromprice');
    case '2':
      return sortByField(sortedList, '-fromprice');
    case '3':
      return sortByField(sortedList, 'durationfromminutes');
    case '4':
      return sortByField(sortedList, '-durationfromminutes');
    default:
      return sortedList;
  }
};

const Results = () => {
  const { t } = useTranslation();

  const searchResults = useSelector(requestStoreService.selector('searchResults'));
  const { selectedTagIds } = useSelector(requestStoreService.selector('searchForm'));

  const orderValue = useRef('0');
  const [filterLoading, setFilterLoading] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const [filterForm, setFilterForm] = useState<FilterForm>();

  const orderChange = (event: SelectChangeEvent) => {
    orderValue.current = event.target.value;
    setResults(ordering(orderValue.current, results));
  };

  const getExcludeSpecials = (resultList: ProductDetail.AsObject[]) => {
    let excludeSpecials = new Set<SpecialsProps>(Object.values(SpecialsProps));

    resultList.forEach((item) => {
      for (const prop of excludeSpecials.values()) {
        if (item.attributeidlistList?.includes(Number(prop))) {
          excludeSpecials.delete(prop);
        }
      }
    });

    return excludeSpecials;
  };

  const disabledSpecials = useMemo<Set<SpecialsProps>>(
    () => (searchResults.value?.length ? getExcludeSpecials(searchResults.value) : new Set()),
    [searchResults.value]
  );

  const priceInfo = useMemo(() => {
    if (!searchResults.value?.length) {
      return { minPrice: 0, maxPrice: 100 };
    }

    const fromPrice = searchResults.value[0].fromprice?.value;
    const startingMinPrice = fromPrice ? Number(fromPrice) : 0;
    let [minPrice, maxPrice] = [startingMinPrice, 100];
    searchResults.value.forEach((item: ProductDetail.AsObject) => {
      if (item.fromprice?.value) {
        const fromPrice = Number(item.fromprice?.value);
        minPrice = fromPrice < minPrice ? fromPrice : minPrice;
        maxPrice = fromPrice > maxPrice ? fromPrice : maxPrice;
      }
    });

    return { minPrice: Math.floor(minPrice), maxPrice: Math.ceil(maxPrice) };
  }, [searchResults.value]);

  useEffect(() => {
    if (!searchResults.value?.length || !filterForm) {
      setResults(ordering(orderValue.current, setInitialResults(searchResults && searchResults.value)));
    } else {
      utils
        .asyncFilter(
          [...searchResults.value],
          (item: ProductDetail.AsObject) =>
            !!item.productname &&
            filterService.ratingFilter(filterForm, item) &&
            filterService.priceFilter(filterForm, item) &&
            filterService.durationFilter(filterForm, item) &&
            filterService.timeOfDayFilter(filterForm, item) &&
            filterService.specialsFilter(filterForm, item)
        )
        .then((products) => {
          setFilterLoading(false);
          setResults(ordering(orderValue.current, products));
        });
    }
  }, [searchResults.value, filterForm]);

  const filteredResults = useMemo(
    () =>
      selectedTagIds.size > 0
        ? results.filter((item) => item.attributeidlistList.some((tagId: number) => selectedTagIds.has(tagId)))
        : results,
    [selectedTagIds, results]
  );

  const { items, hasMore, loadItems, loadMoreRef } = useInfiniteLoading({
    data: filteredResults
  });

  const debouncedHandleFilterChange = utils.debounce((formValues: FilterForm) => {
    setFilterLoading(true);
    setFilterForm(formValues);
  }, 200);

  return (
    <div className="container pt-4">
      <div className="search-results">
        <div className="tag-list">
          <TagSlider />
        </div>
        <div className="filters">
          {/* {priceInfo && specialsInfo && ( */}
          <ResultsFilters
            disabledSpecials={disabledSpecials || []}
            priceInfo={priceInfo}
            currencyCode={results[0]?.currencycode}
            onFilterChange={debouncedHandleFilterChange}
          />
          {/* )} */}
        </div>
        <div className="results box px-4 pb-4 pt-0">
          <div className="results-header px-4 py-3 mb-4">
            <div className="count">
              <span className="number">{filteredResults?.length || 0}</span> {t('productDetails.thingsToDo.xResults')}
            </div>
            <FormControl size="small">
              <InputLabel id="ordering-label">{t('productDetails.thingsToDo.ordering')}</InputLabel>

              <Select
                id="ordering-selector"
                labelId="ordering-label"
                label="Ordering"
                color="secondary"
                onChange={orderChange}
                value={orderValue.current}>
                <MenuItem value={'0'}>{t('productDetails.thingsToDo.ordering.rating')}</MenuItem>
                <MenuItem value={'1'}>{t('productDetails.thingsToDo.ordering.priceToHigh')}</MenuItem>
                <MenuItem value={'2'}>{t('productDetails.thingsToDo.ordering.priceToLow')}</MenuItem>
                <MenuItem value={'3'}>{t('productDetails.thingsToDo.ordering.durationToLong')}</MenuItem>
                <MenuItem value={'4'}>{t('productDetails.thingsToDo.ordering.durationToShort')}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="results-list">
            {!filteredResults.length && !filterLoading && (
              <ErrorBox
                title={t('productDetails.thingsToDo.noResults')}
                description={t('productDetails.thingsToDo.noResultsSubtitle')}
              />
            )}

            {filterLoading ? (
              <div className="results-loader">
                <div className="list">
                  <div className="list-item loader-anim"></div>
                  <div className="list-item loader-anim"></div>
                  <div className="list-item loader-anim"></div>
                </div>
              </div>
            ) : (
              <>
                {items.map((item, index) => (item.productname ? <ResultsItem key={index} result={item} /> : ''))}
                {hasMore && <div ref={loadMoreRef} />}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
