import { AsyncThunk, createReducer, Draft, SerializedError } from '@reduxjs/toolkit';
import { RootState } from 'shared/store/root.store';

export enum RequestStatus {
  Idle = 'idle',
  Loading = 'loading',
  Failed = 'failed'
}

export interface RequestState<T> {
  value?: T;
  status: RequestStatus;
  error: SerializedError | undefined;
}

class RequestReducer {
  public createRequestReducer<T>(thunk: AsyncThunk<T, any, {}>) {
    const requestInitialState: RequestState<T> = {
      value: undefined,
      status: RequestStatus.Idle,
      error: undefined
    };

    return createReducer(requestInitialState, (builder) => {
      builder
        .addCase(thunk.pending, (state) => {
          state.status = RequestStatus.Loading;
          state.value = undefined;
          state.error = undefined;
        })
        .addCase(thunk.fulfilled, (state, action) => {
          state.status = RequestStatus.Idle;
          state.value = action.payload as Draft<T>;
        })
        .addCase(thunk.rejected, (state, action) => {
          state.status = RequestStatus.Failed;
          state.value = undefined;
          state.error = action.error;
          // selectively show toast only
          // toast.error(action.type + '<br />' + action.error.message, { autoClose: 10000, pauseOnHover: true });
        });
    });
  }

  public selector<T extends keyof RootState>(storeName: T) {
    return (state: RootState): RootState[T] => state[storeName];
  }
}

export default new RequestReducer();
