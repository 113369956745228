import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import searchReducer from 'pages/things-to-do/slices/search.slice';
import { useDispatch } from 'react-redux';
import configService from 'shared/services/config.service';
import paymentReducer from 'shared/slices/payment.slice';
import userSettingsReducer from 'shared/slices/user-settings.slice';
import requestReducer from 'shared/store/request.reducer';
import {
  book,
  cancel,
  checkBookingSessionStatus,
  checkout,
  downloadMockReservations,
  getAvailabilityResults,
  getBookingDetails,
  getLocations,
  getMockAccount,
  getMockHighSecurity,
  getMockPaymentStatus,
  getProduct,
  getReservations,
  getTags,
  preBook,
  preCancel,
  productSearch,
  validate
} from 'shared/store/request.thunk';
import listenerMiddleware from './root.middleware';

export const store = configureStore({
  reducer: {
    reservations: requestReducer.createRequestReducer(getReservations),
    bookingDetails: requestReducer.createRequestReducer(getBookingDetails),
    reservationsExport: requestReducer.createRequestReducer(downloadMockReservations),
    product: requestReducer.createRequestReducer(getProduct),
    locations: requestReducer.createRequestReducer(getLocations),
    searchResults: requestReducer.createRequestReducer(productSearch),
    searchTags: requestReducer.createRequestReducer(getTags),
    availability: requestReducer.createRequestReducer(getAvailabilityResults),
    searchForm: searchReducer,
    account: requestReducer.createRequestReducer(getMockAccount),
    highSecurity: requestReducer.createRequestReducer(getMockHighSecurity),
    userSettings: userSettingsReducer,
    payment: paymentReducer,
    paymentStatus: requestReducer.createRequestReducer(getMockPaymentStatus),
    checkout: requestReducer.createRequestReducer(checkout),
    preBook: requestReducer.createRequestReducer(preBook),
    validate: requestReducer.createRequestReducer(validate),
    preCancel: requestReducer.createRequestReducer(preCancel),
    cancel: requestReducer.createRequestReducer(cancel),
    book: requestReducer.createRequestReducer(book),
    bookingSession: requestReducer.createRequestReducer(checkBookingSessionStatus)
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { config: configService.getConfig() }
      },
      serializableCheck: false
    }).prepend(listenerMiddleware.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const useAppDispatch: () => AppDispatch = useDispatch;
