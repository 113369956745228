import { AttractionPromiseClient } from '@BookingPlatform/grpc/v1/Attraction/Attraction_grpc_web_pb';
import {
  AttractionDetailsRequest,
  AttractionDetailsResponse,
  AutoCompleteSearchRequest,
  AutoCompleteSearchResponse,
  AvailabilityRequest,
  AvailabilityResponse,
  Category,
  GetTopCategoryByLocationRequest,
  SearchRequest,
  TopCategoryItem
} from '@BookingPlatform/grpc/v1/Attraction/Attraction_pb';
import { ProductDetail } from '@BookingPlatform/grpc/v1/Attraction/Common/ProductDetail_pb';
import { BookingFlowPromiseClient } from '@BookingPlatform/grpc/v1/BookingFlow_grpc_web_pb';
import {
  BookRequest,
  BookResponse,
  BookingDetailsRequest,
  BookingDetailsResponse,
  BookingItem,
  BookingSearchRequest,
  BookingSearchResponse,
  BookingValidationRequest,
  BookingValidationResponse,
  CancelRequest,
  CancelResponse,
  CancellationItem,
  CheckBookingSessionStatusRequest,
  CheckBookingSessionStatusResponse,
  CheckoutRequest,
  CheckoutResponse,
  PreBookRequest,
  PreBookResponse,
  PreCancelRequest,
  PreCancelResponse
} from '@BookingPlatform/grpc/v1/BookingFlow_pb';
import { BookingQuestionAnswer } from '@BookingPlatform/grpc/v1/Shared/BookingQuestionAnswer_pb';
import { ContactInfo } from '@BookingPlatform/grpc/v1/Shared/ContactInfo_pb';
import { GuestConfiguration } from '@BookingPlatform/grpc/v1/Shared/GuestConfiguration_pb';
import { Phone } from '@BookingPlatform/grpc/v1/Shared/Phone_pb';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { loginRequest, silentTokenRequest } from 'authConfig';
import { format } from 'date-fns';
import { Int32Value, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { ReservationForm } from 'pages/dashboard/reservations/Reservations';
import { SearchForm } from 'pages/things-to-do/search/Search';
import { AsyncThunkOptions } from 'shared/interfaces/config.interface';
import utils from 'shared/services/utilities.service';
import { clearPaymentSession, paymentNotAvailable } from 'shared/slices/payment.slice';
import { store } from './root.store';

const apiUrl = process.env.REACT_APP_BASE_URL as string;
const salesChannelId = parseInt(process.env.REACT_APP_SALES_CHANNEL_ID || '');
const brandId = parseInt(process.env.REACT_APP_BRAND_ID || '');
const tenantId = process.env.REACT_APP_TENANT_ID || '';

export const getBookingDetails = createAsyncThunk<
  BookingDetailsResponse.AsObject,
  { bookingReference: string; instance: IPublicClientApplication; account: AccountInfo }
>('booking-details', async ({ bookingReference, instance, account }) => {
  let request = new BookingDetailsRequest();

  request.setBookingreference(bookingReference);
  request.setSaleschannelid(salesChannelId);
  request.setBrandid(brandId);
  request.setTenantid(tenantId);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).bookingDetails(request, { Authorization: `Bearer ${token}` })
  ).toObject();
});

export const getProduct = createAsyncThunk<
  AttractionDetailsResponse.AsObject,
  { productId: number; instance: IPublicClientApplication; account: AccountInfo; languagecode: string }
>('product', async ({ productId, instance, account, languagecode }) => {
  let request = new AttractionDetailsRequest();
  request.setProductid(productId);
  request.setSaleschannelid(salesChannelId);
  request.setBrandid(brandId);
  request.setTenantid(tenantId);
  request.setLanguagecode(languagecode);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new AttractionPromiseClient(apiUrl).attractionDetails(request, { Authorization: `Bearer ${token}` })
  ).toObject();
});

export const getAvailabilityResults = createAsyncThunk<
  AvailabilityResponse.AsObject,
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    travelDate: string;
    productId: number;
    guestList: Array<GuestConfiguration>;
  }
>('availability', async ({ instance, account, travelDate, productId, guestList }) => {
  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  const request = new AvailabilityRequest();

  request.setSaleschannelid(salesChannelId);
  request.setBrandid(brandId);
  request.setTenantid(tenantId);
  request.setProductid(productId);
  request.setCurrencycode(utils.getCurrentCurrency());
  request.setGuestsList(guestList);
  request.setLanguagecode(utils.getCurrentLanguage());
  request.setTraveldate(travelDate);

  return (
    await new AttractionPromiseClient(apiUrl).availability(request, { Authorization: `Bearer ${token}` })
  ).toObject();
});

export const getLocations = createAsyncThunk<
  AutoCompleteSearchResponse.AsObject,
  { searchString: string; instance: IPublicClientApplication; account: AccountInfo }
>('locations', async ({ searchString, instance, account }) => {
  let request = new AutoCompleteSearchRequest();

  request.setLanguagecode(utils.getCurrentLanguage());
  request.setSearchstring(searchString);
  request.setSaleschannelid(salesChannelId);
  request.setBrandid(brandId);
  request.setTenantid(tenantId);
  request.setCategoriesList([Category.ATTRACTION, Category.ATTRACTION_LOCATION]);
  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }
  return (
    await new AttractionPromiseClient(apiUrl).autoComplete(request, { Authorization: `Bearer ${token}` })
  ).toObject();
});

interface SearchApiCallArgs {
  searchForm: SearchForm;
  instance: IPublicClientApplication;
  account: AccountInfo;
}

interface SearchClearArgs {
  isClear: boolean;
}

export const productSearch = createAsyncThunk<
  ProductDetail.AsObject[] | null,
  SearchApiCallArgs | SearchClearArgs,
  AsyncThunkOptions
>('productSearch', async (args, options) => {
  if ((args as SearchClearArgs).isClear) {
    return null;
  }

  const { searchForm, account, instance } = args as SearchApiCallArgs;

  let request = new SearchRequest();

  // let config = await options.extra.config;
  const searchType = searchForm.location?.type;
  const searchId = searchForm.location?.id;

  // TODO: Get values dynamically once BE can handle it
  request.setLanguagecode(utils.getCurrentLanguage());
  request.setCurrencycode(utils.getCurrentCurrency());

  if (searchType === Category.ATTRACTION && searchId) {
    request.setProductidsList([searchId]);
  }

  if (searchType === Category.ATTRACTION_LOCATION && searchId) {
    request.setLocationid(new Int32Value().setValue(searchId));
  }

  request.setSaleschannelid(salesChannelId);
  request.setBrandid(brandId);
  request.setTenantid(tenantId);

  // TODO: Add back the date when BE is ready
  request.setStartdate(DateTime.fromJSDate(searchForm.date.startDate).toFormat('yyyy-MM-dd'));
  request.setEnddate(DateTime.fromJSDate(searchForm.date.endDate).toFormat('yyyy-MM-dd'));

  let token;

  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect(loginRequest);
  }

  return (await new AttractionPromiseClient(apiUrl).search(request, { Authorization: `Bearer ${token}` })).toObject()
    .resultsList;
});

export const getReservations = createAsyncThunk<
  BookingSearchResponse.AsObject,
  { formData: ReservationForm; instance: IPublicClientApplication; account: AccountInfo }
>('reservations', async ({ formData, instance, account }) => {
  let request = new BookingSearchRequest();

  const salesChannelIdAsInt32 = new Int32Value();
  salesChannelIdAsInt32.setValue(salesChannelId);

  const brandIdAsInt32 = new Int32Value();
  brandIdAsInt32.setValue(brandId);

  request.setSaleschannelid(salesChannelIdAsInt32);
  request.setBrandid(brandIdAsInt32);
  request.setTenantid(tenantId);

  if (formData.reference) {
    const partnerBookingReferenceAsStringValue = new StringValue();
    partnerBookingReferenceAsStringValue.setValue(formData.reference);

    request.setPartnerbookingreference(partnerBookingReferenceAsStringValue);
  }

  if (formData['travel-date']?.startDate) {
    const travelDateFromAsString = new StringValue();
    travelDateFromAsString.setValue(formData['travel-date'].startDate.toISOString().substring(0, 10));

    request.setStartdatefrom(travelDateFromAsString);
  }

  if (formData['travel-date']?.endDate) {
    const travelDateToAsString = new StringValue();
    travelDateToAsString.setValue(formData['travel-date'].endDate.toISOString().substring(0, 10));

    request.setStartdateto(travelDateToAsString);
  }

  if (formData['booking-date']?.startDate) {
    const bookingDateFromAsString = new StringValue();
    bookingDateFromAsString.setValue(formData['booking-date'].startDate.toISOString().substring(0, 10));

    request.setBookingdatefrom(bookingDateFromAsString);
  }

  if (formData['booking-date']?.endDate) {
    const bookingDateToAsString = new StringValue();
    bookingDateToAsString.setValue(formData['booking-date'].endDate.toISOString().substring(0, 10));

    request.setBookingdateto(bookingDateToAsString);
  }

  if (formData.status && formData.status !== 'All') {
    const statusAsString = new StringValue();
    statusAsString.setValue(formData.status);

    request.setBookingstatus(statusAsString);
  }

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }
  return (
    await new BookingFlowPromiseClient(apiUrl).bookingSearch(request, { Authorization: `Bearer ${token}` })
  ).toObject();
});

// ⬇️ Mock requests
// ****************
export const mockProductSearch = createAsyncThunk<ProductDetail.AsObject[], any>('product', async (_formData) => {
  const mockPromise: Promise<ProductDetail.AsObject[]> = fetch('/mocks/search-results.json').then((data) =>
    data.json()
  );

  await utils.delay(2000);

  return mockPromise;
});

export const getMockAvailabilityResults = createAsyncThunk('mockAvailability', async () => {
  const mockPromise: Promise<AvailabilityResponse.AsObject> = fetch('/mocks/availability.json').then((data) => {
    return data.json();
  });

  await utils.delay(1000);

  // Dummy check for demo
  // if (travellers[0].guesttypenumber > 1) {
  //   throw new Error('Error!');
  // }

  return mockPromise;
});

export const getMockReservations = createAsyncThunk('mock-reservations', async (formData?: ReservationForm) => {
  const mockPromise: Promise<Array<any>> = fetch('/mocks/reservations.json').then((data) => {
    if (formData?.reference) {
      throw new Error(i18next.t('reservation.error'));
    }

    return data.json();
  });

  await utils.delay(500);

  return mockPromise;
});

export const getMockPreBook = createAsyncThunk('mock-prebook', async () => {
  // const mockPromise: Promise<{
  //   dummyTravellerList: ResponseTraveller[];
  //   otherFormList: GenericFormList[];
  //   currencyCode: Currency;
  //   priceDetails: {
  //     retailPrice: number;
  //   };
  //   timezone: string;
  // }> = fetch('/mocks/prebook.json').then((data) => {
  //   return data.json();
  // });
  // await utils.delay(500);
  // const mock = await mockPromise;
  // return {
  //   ...mock,
  //   priceDetails: { ...mock.priceDetails, retailPrice: Math.round(Math.random() * 1000) }
  // };
});

export interface Tag {
  attributeid: number;
  sortorder: number;
  attributename: string;
  imageurl: string;
  productCount?: number;
}

export const getTags = createAsyncThunk<
  TopCategoryItem.AsObject[],
  { locationid: number; instance: IPublicClientApplication; account: AccountInfo; languagecode: string }
>('tags', async ({ locationid, account, instance, languagecode }) => {
  let request = new GetTopCategoryByLocationRequest();

  request.setLocationid(locationid);
  request.setLanguagecode(languagecode);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new AttractionPromiseClient(apiUrl).getTopCategoryByLocation(request, {
      Authorization: `Bearer ${token}`
    })
  ).toObject().topcategoriesList;
});

export const downloadMockReservations = createAsyncThunk('reservations-download', async () => {
  const download = fetch('/mocks/reservations.json')
    .then((res) => {
      return res.blob();
    })
    .then((data) => {
      let a = document.createElement('a');
      a.href = window.URL.createObjectURL(data);
      a.download = 'reservation-list-' + format(new Date(), 'yyyy-MM-dd');
      a.click();
    });

  await utils.delay(200);

  return download;
});

export interface AccountResponseItem {
  name: string;
  value: string;
  enabled: boolean;
}

export const getMockAccount = createAsyncThunk('mock-account', async () => {
  const mockPromise: Promise<Array<AccountResponseItem>> = fetch('/mocks/account.json').then((data) => data.json());

  await utils.delay(200);

  return mockPromise;
});

export interface HighSecurityResponse {
  isValidForHighSecurity: boolean;
}

export interface HighSecurityRequest {
  isClear: boolean;
}

export const getMockHighSecurity = createAsyncThunk('mock-high-security', async (request?: HighSecurityRequest) => {
  const mockPromise: Promise<HighSecurityResponse> = new Promise((resolve, reject) => {
    resolve({ isValidForHighSecurity: false });
  });

  await utils.delay(500);

  return request?.isClear ? null : mockPromise;
});

export const postPaymentSession =
  ({ instance, account }: { instance: IPublicClientApplication; account: AccountInfo }) =>
  async (dispatch: Dispatch, getState: typeof store.getState) => {
    // let request = new CreateBookingSessionRequest();
    // request.setBookingitemsList([]);

    // let token;
    // try {
    //   const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    //   token = accessToken;
    // } catch (e) {
    //   instance.loginRedirect();
    // }
    // eslint-disable-next-line no-console
    try {
      // const response = (
      //   await new BookingSessionPromiseClient(apiUrl).createBookingSession(request, {
      //     Authorization: `Bearer ${token}`
      //   })
      // ).toObject();
      // dispatch(createPaymentSession([response.sessionjson, 'hello mock order id']));
    } catch {
      dispatch(clearPaymentSession());
      dispatch(paymentNotAvailable());
    }
  };

export type MockPaymentStatusType = 'success' | 'failed' | 'pending';

interface PaymentStatusRequest {
  // simple mock statuses, tbd
  status: MockPaymentStatusType;
}

export const getMockPaymentStatus = createAsyncThunk('mock-payment-status', async (request: PaymentStatusRequest) => {
  const mockPromise: Promise<PaymentStatusRequest> = new Promise((resolve, reject) => {
    resolve({ status: request.status });
  });

  await utils.delay(500);

  return mockPromise;
});

export const checkout = createAsyncThunk<
  CheckoutResponse.AsObject,
  { instance: IPublicClientApplication; account: AccountInfo; itemTokens: string[] }
>('checkout', async ({ instance, account, itemTokens }) => {
  const request = new CheckoutRequest();

  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setTenantid(tenantId);
  request.setOptionbooktokensList(itemTokens);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).checkout(request, {
      Authorization: `Bearer ${token}`
    })
  ).toObject();
});

export const validate = createAsyncThunk<
  BookingValidationResponse.AsObject,
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    itemTokens: string[];
    contactInfo: ContactInfo.AsObject;
    bookingQuestionAnswers: BookingQuestionAnswer.AsObject[];
  }
>('validate', async ({ instance, account, itemTokens, contactInfo, bookingQuestionAnswers }) => {
  const request = new BookingValidationRequest();
  const contact = new ContactInfo();
  const phone = new Phone();

  let answers: any[] = [];

  bookingQuestionAnswers.forEach((element) => {
    const answer = new BookingQuestionAnswer();
    answer.setQuestionid(element.questionid);
    answer.setAnswer(element.answer);
    element.guesttype && answer.setGuesttype(element.guesttype);

    if (element.unit) {
      const string = new StringValue();
      string.setValue(element.unit.value);
      answer.setUnit(string);
    }
    if (element.guestnum) {
      const string = new Int32Value();
      string.setValue(element.guestnum.value);
      answer.setGuestnum(string);
    }
    answers.push(answer);
  });

  phone.setCountrycode(contactInfo.phone?.countrycode || '');
  phone.setNumber(contactInfo.phone?.countrycode || '');
  contact.setPhone(phone);
  contact.setEmail(contactInfo.email);
  contact.setFirstname(contactInfo.firstname);
  contact.setLastname(contactInfo.lastname);
  request.setTenantid(tenantId);
  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setOptiontoken(itemTokens[0]);
  request.setContactinfo(contact);
  // console.log('validationnn')
  request.setBookingquestionanswersList(answers);
  // console.log('validationnn success')

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).validateBooking(request, {
      Authorization: `Bearer ${token}`
    })
  ).toObject();
});

export const preBook = createAsyncThunk<
  PreBookResponse.AsObject,
  { instance: IPublicClientApplication; account: AccountInfo; optionToken: string }
>('preBook', async ({ instance, account, optionToken }) => {
  const request = new PreBookRequest();

  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setTenantid(tenantId);
  request.setOptiontoken(optionToken);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).prebook(request, {
      Authorization: `Bearer ${token}`
    })
  ).toObject();
});

export const book = createAsyncThunk<
  BookResponse.AsObject,
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    contactInfo: ContactInfo.AsObject;
    bookingQuestionAnswers: BookingQuestionAnswer.AsObject[];
    itemTokens: string[];
  }
>('book', async ({ instance, account, contactInfo, bookingQuestionAnswers, itemTokens }) => {
  const request = new BookRequest();

  const bookingItem = new BookingItem();
  const contact = new ContactInfo();
  const phone = new Phone();

  let answers: any[] = [];

  bookingQuestionAnswers.forEach((element) => {
    const answer = new BookingQuestionAnswer();
    answer.setQuestionid(element.questionid);
    answer.setAnswer(element.answer);
    element.guesttype && answer.setGuesttype(element.guesttype);

    if (element.unit) {
      const string = new StringValue();
      string.setValue(element.unit.value);
      answer.setUnit(string);
    }
    if (element.guestnum) {
      const string = new Int32Value();
      string.setValue(element.guestnum.value);
      answer.setGuestnum(string);
    }
    answers.push(answer);
  });

  bookingItem.setBookingquestionanswersList(answers);
  bookingItem.setOptionbooktoken(itemTokens[0]);

  phone.setCountrycode(contactInfo.phone?.countrycode || '');
  phone.setNumber(contactInfo.phone?.countrycode || '');
  contact.setPhone(phone);
  contact.setEmail(contactInfo.email);
  contact.setFirstname(contactInfo.firstname);
  contact.setLastname(contactInfo.lastname);

  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setTenantid(tenantId);
  request.setBookingitemsList([bookingItem]);
  request.setContactinfo(contact);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).book(request, {
      Authorization: `Bearer ${token}`
    })
  ).toObject();
});

export const checkBookingSessionStatus = createAsyncThunk<
  CheckBookingSessionStatusResponse.AsObject,
  { bookingSessionId: string; instance: IPublicClientApplication; account: AccountInfo }
>('bookingSession', async ({ instance, account, bookingSessionId }) => {
  const request = new CheckBookingSessionStatusRequest();

  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setTenantid(tenantId);
  request.setBookingsessionid(bookingSessionId);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).checkBookingSessionStatus(request, {
      Authorization: `Bearer ${token}`
    })
  ).toObject();
});

export const preCancel = createAsyncThunk<
  PreCancelResponse.AsObject,
  { bookingReference: string; instance: IPublicClientApplication; account: AccountInfo }
>('preCancel', async ({ instance, account, bookingReference }) => {
  const request = new PreCancelRequest();

  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setTenantid(tenantId);
  request.setBookingreference(bookingReference);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).preCancel(request, {
      Authorization: `Bearer ${token}`
    })
  ).toObject();
});

export const cancel = createAsyncThunk<
  CancelResponse.AsObject,
  {
    bookingReference: string;
    cancellationItems: CancellationItem[];
    instance: IPublicClientApplication;
    account: AccountInfo;
  }
>('cancel', async ({ instance, account, bookingReference, cancellationItems }) => {
  const request = new CancelRequest();

  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setTenantid(tenantId);
  request.setBookingreference(bookingReference);
  request.setBookingcomponentsList(cancellationItems);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).cancel(request, {
      Authorization: `Bearer ${token}`
    })
  ).toObject();
});
