import { BookingSessionStatus } from '@BookingPlatform/grpc/v1/Shared/BookingSessionStatus_pb';
import { useMsal } from '@azure/msal-react';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import requestStoreService, { RequestStatus } from 'shared/store/request.reducer';
import { checkBookingSessionStatus } from 'shared/store/request.thunk';
import { AppDispatch } from 'shared/store/root.store';
import Failed from './Failed';
import './PaymentStatus.scss';
import Pending from './Pending';
import Success from './Success';

const PaymentStatus = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { accounts, instance } = useMsal();
  const bookStore = useSelector(requestStoreService.selector('book'));
  const bookingSessionStore = useSelector(requestStoreService.selector('bookingSession'));

  const counter = useRef<number>(0);
  const intervalSize = useRef<number>(2);

  const interval = useRef<NodeJS.Timer | undefined>();

  const isPending = !!(
    bookingSessionStore.value &&
    (bookingSessionStore.value.bookingstatus === BookingSessionStatus.BOOKINGINPROGRESS ||
      bookingSessionStore.value.bookingstatus === BookingSessionStatus.PAYMENTINPROGRESS)
  );
  const isSuccess = !!(
    bookingSessionStore.value && bookingSessionStore.value.bookingstatus === BookingSessionStatus.BOOKINGCOMPLETED
  );
  const isFailed = !!(
    bookingSessionStore.value &&
    (bookingSessionStore.value.bookingstatus === BookingSessionStatus.BOOKINGFAILED ||
      bookingSessionStore.value.bookingstatus === BookingSessionStatus.PAYMENTFAILED)
  );

  useEffect(() => {
    if (typeof interval.current === 'undefined' && bookStore?.value?.bookingsessionid) {
      interval.current = setInterval(() => {
        counter.current++;
        if (counter.current % intervalSize.current === 0) {
          dispatch(
            checkBookingSessionStatus({
              instance,
              account: accounts[0],
              bookingSessionId: bookStore.value?.bookingsessionid ?? ''
            })
          );
          intervalSize.current = intervalSize.current * 2;
        }
      }, 1000);
    }
    if (isFailed || isSuccess) {
      clearInterval(interval.current);
    }
  }, [dispatch, isFailed, isSuccess]);

  return (
    <div className="payment-status">
      {bookingSessionStore.status === RequestStatus.Loading ? (
        <CircularProgress />
      ) : isSuccess ? (
        <Success />
      ) : isPending ? (
        <Pending />
      ) : isFailed ? (
        <Failed />
      ) : (
        <Pending />
      )}
    </div>
  );
};

export default PaymentStatus;
