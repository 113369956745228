class UtilsService {
  public debounce = (callback: any, delay: number) => {
    let timer: any;

    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  };

  public asyncFilter = async (array: Array<any>, predicate: any) => {
    const results = await Promise.all(array.map(predicate));
    return array.filter((_v, index) => results[index]);
  };

  public delay = (ms: any) => new Promise((r) => setTimeout(r, ms));

  public getCurrentLanguage = () => localStorage.getItem('languageCode') || 'en-GB';
  public getCurrentCurrency = () => localStorage.getItem('currencyCode') || 'GBP';

  public formatCurrency = (price: number, currency: string) => {
    return new Intl.NumberFormat(this.getCurrentLanguage(), {
      style: 'currency',
      currency: currency || this.getCurrentCurrency()
    }).format(price);
  };

  public formatText = (string: string) => {
    const lowerCase: string = string.replaceAll('_', ' ').toLowerCase();
    return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
  };
}

export default new UtilsService();
