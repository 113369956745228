import { Component, ReactNode } from 'react';
import ErrorTitle from '../error-title/ErrorTitle';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="container">
          <ErrorTitle title="errorBoundary.title" subtitle="errorBoundary.subtitle" />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
