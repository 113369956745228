import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency } from 'pages/account/my-account-from/MyAccountForm';

export interface UserSettingsState {
  currency: Currency;
  language: string;
  timezone: string;
}

export const initialState: UserSettingsState = {
  currency: 'EUR',
  language: '',
  timezone: ''
};

export const userSettingsSlice = createSlice({
  name: 'user-settings',
  initialState,
  reducers: {
    setCurrency: (state, action: PayloadAction<Currency>) => {
      state.currency = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload;
    }
  }
});
export const { setCurrency, setLanguage, setTimezone } = userSettingsSlice.actions;
export default userSettingsSlice.reducer;
