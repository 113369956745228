import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { FC } from 'react';
import './ErrorBox.scss';

const ErrorBox: FC<{ title: string; description: string }> = ({ title, description }) => {
  return (
    <div className="error-box p-4">
      <WarningAmberIcon />
      <div className="text">
        <h4 className="h4">{title}</h4>
        <p className="m-0">{description}</p>
      </div>
    </div>
  );
};

export default ErrorBox;
