import CloseIcon from '@mui/icons-material/Close';
import { FC, MouseEventHandler } from 'react';
import { Tag } from 'shared/store/request.thunk';
import './SearchTag.scss';

interface ISearchTag {
  tag: Tag;
  onClick: MouseEventHandler;
  active?: boolean;
}

const SearchTag: FC<ISearchTag> = ({ tag, onClick, active = false }) => {
  return (
    <div className={'search-tag' + (active ? ' active' : '')} onClick={onClick}>
      <div className="image">
        <img
          src={tag.imageurl}
          alt=""
          onError={(event) => {
            event.currentTarget.style.display = 'none';
          }}
        />
        <CloseIcon />
      </div>
      <div className="name">{tag.attributename}</div>
    </div>
  );
};

export default SearchTag;
