import { GuestType } from '@BookingPlatform/grpc/v1/Shared/GuestType_pb';
import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BookingComponentStatusChip from 'shared/components/booking-component-status-chip/BookingComponentStatusChip';
import { PageRoute } from 'shared/interfaces/config.interface';
import requestStoreService from 'shared/store/request.reducer';
import { getBookingDetails, getProduct } from 'shared/store/request.thunk';
import { RootState, useAppDispatch } from 'shared/store/root.store';
import './BookingConfirmation.scss';

const BookingConfirmation = () => {
  const dispatch = useAppDispatch();
  const { accounts, instance } = useMsal();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { language } = useSelector((state: RootState) => state.userSettings);
  const bookingSessionStore = useSelector(requestStoreService.selector('bookingSession'));
  const bookingDetailsStore = useSelector(requestStoreService.selector('bookingDetails'));
  const productStore = useSelector(requestStoreService.selector('product'));

  const handleGetTicket = () => {
    if (bookingDetailsStore.value?.bookingcomponentdetailsList[0].voucherurl) {
      window.open(bookingDetailsStore.value.bookingcomponentdetailsList[0].voucherurl.value);
    }
  };

  const getTravelYear = () => {
    if (bookingDetailsStore.value?.bookingcomponentdetailsList[0]) {
      const startDate = new Date(bookingDetailsStore.value.bookingcomponentdetailsList[0].startdate);

      return startDate.getFullYear();
    }
  };

  const getTravelMonth = () => {
    if (bookingDetailsStore.value?.bookingcomponentdetailsList[0]) {
      const startDate = new Date(bookingDetailsStore.value.bookingcomponentdetailsList[0].startdate);
      const month = startDate.toLocaleString('default', { month: 'short' });

      return month;
    }
  };

  const getTravelDay = () => {
    if (bookingDetailsStore.value?.bookingcomponentdetailsList[0]) {
      const startDate = new Date(bookingDetailsStore.value.bookingcomponentdetailsList[0].startdate);

      return startDate.getDate();
    }
  };

  const getGuestTypeName = (guestType: GuestType) => {
    switch (guestType) {
      case GuestType.ADULT:
        return t('bookingConfirmationPage.guestType.adult');
      case GuestType.CHILD:
        return t('bookingConfirmationPage.guestType.child');
      case GuestType.INFANT:
        return t('bookingConfirmationPage.guestType.infant');
      case GuestType.SENIOR:
        return t('bookingConfirmationPage.guestType.senior');
      case GuestType.TRAVELLER:
        return t('bookingConfirmationPage.guestType.traveller');
      case GuestType.YOUTH:
        return t('bookingConfirmationPage.guestType.youth');
    }
  };

  const navigateToBookingDetails = () => {
    navigate(`${PageRoute.BookingDetails}/${bookingSessionStore.value?.bookingreference?.value}`);
  };

  useEffect(() => {
    if (bookingSessionStore.value?.bookingreference) {
      dispatch(
        getBookingDetails({
          bookingReference: bookingSessionStore.value.bookingreference.value,
          instance,
          account: accounts[0]
        })
      );
    }
  }, [dispatch, accounts, instance, bookingSessionStore.value?.bookingreference]);

  useEffect(() => {
    if (bookingDetailsStore.value) {
      dispatch(
        getProduct({
          productId: bookingDetailsStore.value.bookingcomponentdetailsList[0].productid,
          instance,
          account: accounts[0],
          languagecode: language
        })
      );
    }
  }, [accounts, dispatch, instance, bookingDetailsStore.value]);

  return (
    <>
      {bookingDetailsStore.value && productStore.value && (
        <div className="booking-confirmation">
          <a onClick={navigateToBookingDetails} className="title">
            {t('bookingConfirmationPage.title')}
          </a>
          <div className="container">
            <div className="date-container">
              <div className="date">
                <div className="month">{getTravelMonth()}</div>
                <div className="day">{getTravelDay()}</div>
                <div className="year">{getTravelYear()}</div>
              </div>
            </div>
            <div className="main-container">
              <BookingComponentStatusChip
                status={bookingDetailsStore.value.bookingcomponentdetailsList[0].componentstatus}
              />
              <div className="attraction-name">{productStore.value.productname?.value}</div>
              {bookingDetailsStore.value.bookingcomponentdetailsList[0].productoptionsList.length > 0 && (
                <div className="group">
                  {bookingDetailsStore.value.bookingcomponentdetailsList[0].productoptionsList[0].optionname?.value}
                </div>
              )}
              <div className="total">{`${t('bookingConfirmationPage.total')}: ${
                bookingDetailsStore.value.bookingcomponentdetailsList[0].totalprice?.value
              } ${bookingDetailsStore.value.currencycode}`}</div>
              {bookingDetailsStore.value.bookingcomponentdetailsList[0].productoptionsList.length > 0 &&
                bookingDetailsStore.value.bookingcomponentdetailsList[0].productoptionsList[0].guestconfigurationsList.map(
                  (guestConfig) => (
                    <div className="breakdown">{`(${guestConfig.guesttypenumber} x ${getGuestTypeName(
                      guestConfig.guesttype
                    )})`}</div>
                  )
                )}
            </div>
            <div className="booking-reference-container">
              <div className="booking-reference">
                {t('bookingConfirmationPage.bookingReference')}: {bookingSessionStore.value?.bookingreference?.value}
              </div>
              <Button onClick={handleGetTicket} variant="outlined">
                {t('bookingConfirmationPage.getTicket')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookingConfirmation;
